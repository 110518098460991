import { AdjudicationSummaryItem } from "../../api";

interface DocumentData {
  [key: string]: {
    [key: string]: string | number;
  };
}

interface SummaryData {
  [key: string]: string;
}
interface ProcedureRequest {
  overview: string;
  adjudication_summary: string[];
}

export const extractValues: DocumentData = {
  "Enrolee Info.pdf": {
    "Date of Birth": "02/21/2000",
    "Pharmacy Name": "Rite Aid #05319",
    "Pharmacy NPI": "1871602193",
    Prescriber: "Ruth Adeptun",
    "Prescriber NPI": "1518362862",
    "Medication and Strength": "Vraylar 1.5 mg capsule",
    "Qty/Days Supply": "30/30",
    "Diagnosis code": "F31.81",
    "Diagnosis code description": "bipolar II disorder",
  },
  "146132852320240319.pdf": {
    "Member Name": "Kelly Kurtz SR",
    DOB: "03/08/1976",
    "Molina Member ID": "110000174470",
    "Clinic Name": "Community Health Asc. of Spokane CHAS North County Clinic",
    "PCP Name": "John Kabosky",
    "PCP address": "401 Main St",
    City: "Deer Park",
    State: "WA",
    ZIP: "99006",
  },
  "188685422520240319.pdf": {
    "Member Name": "Yaretzi Cruz Jimenez",
    DOB: "12/22/2011",
    "Molina Member ID": "110001453417",
    "Clinic Name": "Quincy Community Health Center",
    "PCP Name": "India Wolfendale, PA-C",
    "PCP address": "1450 IST AVE SW",
    City: "Quincy",
    State: "WA",
    ZIP: "98848",
  },
  "156116407220240319.pdf": {
    "Member Name": "Frankie C Sweowat - Kheel",
    DOB: "8/11/23",
    "Molina Member ID": "110066481189",
    "Clinic Name": "Community Health Asc. of Spokane CHAS Perry St. Clinic",
    "PCP Name": "Deborah Wiser, MD",
    "PCP address": "817 S Perry St Suite B ",
    City: "Spokane",
    State: "WA",
    ZIP: "99202",
  },
  "165882770520240319.pdf": {
    "Member Name": "Shawna Hutchings",
    DOB: "09/21/1979",
    "Molina Member ID": "100000397767",
    "Clinic Name": "Unify Community Health",
    "PCP Name": "Natalie McPherson",
    "PCP address": "120 W Mission",
    City: "Spokane",
    State: "WA",
    ZIP: "99201",
  },
  "198485391920240319.pdf": {
    "Member Name": "Henry Berg",
    DOB: "1/17/2024",
    "Molina Member ID": "110068765125",
    "Clinic Name": "The Doctors Clinic",
    "PCP Name": "Dr. Gregory Hoisington",
    "PCP address": "1780 NW Myhre Rd #2120",
    City: "Silverdale",
    State: "WA",
    ZIP: "98383",
  },
  "Tenoudji 91213 0524.pdf": {
    Segment: "Third Party",
    "New Rx's": "439",
    "Refill Rx's": "797",
    "Total Cost": "$110075.34",
    "Total Price": "$72725.64",
  },
  "FX037042.pdf": {
    Segment: "PREPAID",
    "New Rx's": "Not applicable",
    "Refill Rx's": "Not applicable",
    "Total Cost": "507.77",
    "Total Price": "507.77",
  },
  "PBMCase1.pdf":{
    "Date of Birth": "1/1/1991",
    "Pharmacy Name": "Rite Aid #053213",
    "Pharmacy NPI": 1871602112,
    "Prescriber": "Lisa A",
    "Prescriber NPI": 1231231231,
    "Medication and Strength": "Lidocaine PTCH 5%",
    "Qty/Days Supply": 10,
    "Diagnosis code": "M25.511"
  },
  "PBMCase2.pdf":{
    "Date of Birth": "1/1/1973",
    "Pharmacy Name": "Rite Aid #053985",
    "Pharmacy NPI": 1871602231,
    "Prescriber": "Homer S",
    "Prescriber NPI": 1231231345,
    "Medication and Strength": "Cibinqo 100MG OR TABS",
    "Qty/Days Supply": 30,
    "Diagnosis code": "L20.89",
  },
  "PBMCase3.pdf":{
    "Date of Birth": "1/1/1994",
    "Pharmacy Name": "Rite Aid #053000",
    "Pharmacy NPI": 1871602001,
    "Prescriber": "Bartholomeu A",
    "Prescriber NPI": 1231231999,
    "Medication and Strength": "Retin-A 0.05% EX CREA",
    "Qty/Days Supply": 20,
    "Diagnosis code": "L70.0"
  },
  "PBMCase4.pdf":{
    "Date of Birth": "1/1/1978",
    "Pharmacy Name": "Rite Aid #053998",
    "Pharmacy NPI": 1871602043,
    "Prescriber": "Maggie A",
    "Prescriber NPI": 1231231945,
    "Medication and Strength": "Botox SOLR 200 UNIT",
    "Qty/Days Supply": "Not provided",
    "Diagnosis code": "G43.709"    
  }
};

export const nestedValues: any = {
  "Billing Document - 8007307259.pdf": {
    "Supplier Name": "Stericycle, Inc.",
    "Supplier Address": "28883 Network Place, Chicago, IL 60673-1288",
    "Invoice Number": "8007307259",
    "Invoice Date": "06-03-2024",
    "Invoice Due Date": "07-03-2024",
    "Payment Terms": "Net due in 30 days",
    "Purchase Order (PO) Number": "8164528034",
    "Line Item Information": {
      "Item Number": "Not specified",
      Description: "REGULAR SERVICE | ON-SITE (PAPER), CONSOLE (STANDARD)",
      "Number of Items": 4,
      "Unit Price": 164.43,
      "Total Price": 164.43,
      Tax: 0.0,
      Freight: 0.0,
      "Recycling Recovery Surcharge": 22.86,
      "Fuel Surcharge": 27.95,
      "Environmental Surcharge": 6.58,
    },
    "Total Amount Due": 221.82,
    "Currency Type": "USD",
  },
  "Inv_31289_from_SILICONES_PLUS_INC._1412.pdf": {
    "Supplier Name": "Silicones Plus Inc.",
    "Supplier Address": "C/O: Amegy-Lockbox, Dept. 8147, PO BOX 650002, Dallas, TX. 75265-0002",
    "Invoice Number": "31289",
    "Invoice Date": "6/5/2024",
    "Invoice Due Date": "7/5/2024",
    "Payment Terms": "NET30",
    "Purchase Order (PO) Number": "130126",
    "Line Item Information": {
      "Item Number": "712477149097 (P/N 30-4880)",
      Description: "BOSEXIL (5KG/NET/CONTAINER)",
      "Number of Items": "1 container (5 units)",
      "Unit Price": 686.4,
      "Total Price": 3432.0,
      Tax: "Not specified",
      Freight: "Not specified (Ship via FED EX GROUND, F.O.B. ORIGIN/COL.)",
    },
    "Total Amount Due": 3432.0,
    "Currency Type": "USD",
  },
  "Inv_65375_from_Exakt_Technologies_Inc_10832.pdf": {
    "Supplier Name": "Exakt Technologies Inc.",
    "Supplier Address": "13501 Railway Dr., Oklahoma City, OK 73114",
    "Invoice Number": "65375",
    "Invoice Date": "6/6/2024",
    "Invoice Due Date": "7/6/2024",
    "Payment Terms": "Net 30",
    "Purchase Order (PO) Number": "130996",
    "Line Item Information": {
      "Item Number": "22300",
      Description: "Plastic Guide for E50 EC",
      "Number of Items": 2,
      "Unit Price": 179.2,
      "Total Price": 179.2,
      Tax: 0.0,
      Freight: "Not specified (Ship via Cust FedEx)",
    },
    "Total Amount Due": 179.2,
    "Currency Type": "USD",
  },
  "Invoice-2024-83163-00.pdf": {
    "Supplier Name": "Reliance Vitamin LLC",
    "Supplier Address": "3775 Park Avenue, Edison, NJ 08820",
    "Invoice Number": "2024-83163-00",
    "Invoice Date": "06/03/2024",
    "Invoice Due Date": "07/06/2024",
    "Payment Terms": "2% 10-Net 30",
    "Purchase Order (PO) Number": "131031",
    "Line Item Information": [
      {
        "Part Number": "1182-120",
        Description: "Prenatal Plus Tabs 120's",
        "Number of Items": 36,
        "Unit Price": 9.71,
        "Total Price": 349.65,
      },
      {
        "Part Number": "2252-60",
        Description: "Adrenal Support Vcaps 60's",
        "Number of Items": 192,
        "Unit Price": 10.84,
        "Total Price": 2080.8,
      },
      {
        "Part Number": "3251-100",
        Description: "Vitamin B6 100Mg Tabs 100's",
        "Number of Items": 24,
        "Unit Price": 3.83,
        "Total Price": 91.8,
      },
      {
        "Part Number": "4441-100",
        Description: "C 1000 With 100Mg Bio Caps 100's",
        "Number of Items": 192,
        "Unit Price": 7.28,
        "Total Price": 1396.8,
      },
      {
        "Part Number": "5341-30",
        Description: "Phosphatidyl Serine 500Mg Softgels 30's",
        "Number of Items": 180,
        "Unit Price": 9.3,
        "Total Price": 1674.0,
      },
      {
        "Part Number": "6781-60",
        Description: "Ultra Veggie Enzymes Vcaps 60's",
        "Number of Items": 60,
        "Unit Price": 8.21,
        "Total Price": 492.75,
      },
      {
        "Part Number": "6942-60",
        Description: "Nac 600 Plus Caps 60's",
        "Number of Items": 72,
        "Unit Price": 7.28,
        "Total Price": 524.16,
      },
      {
        "Part Number": "8511-100",
        Description: "Omega-3 Softgel 100's",
        "Number of Items": 36,
        "Unit Price": 4.54,
        "Total Price": 163.35,
      },
      {
        "Part Number": "8822-60",
        Description: "Brain Support Vcaps 60's",
        "Number of Items": 36,
        "Unit Price": 11.21,
        "Total Price": 403.65,
      },
      {
        "Part Number": "9111-90",
        Description: "All Season Support Tabs 90's",
        "Number of Items": 24,
        "Unit Price": 14.21,
        "Total Price": 341.1,
      },
    ],
    Tax: "Not specified",
    Freight: "Prepaid and Add (Estes)",
    "Total Amount Due": 7518.06,
    "Currency Type": "USD",
  },
}

export const extractValuesSummary: SummaryData = {
  "Enrolee Info.pdf": `General Summary:
  The medications include Vraylar oral capsule 1.5 mg, venlafaxine ER 150 mg capsule (extended release 24 hr), and venlafaxine ER 37.5 mg capsule (extended release 24 hr). The instructions for Vraylar are to take 1 capsule (1.5 mg) orally once daily in the afternoon. The prescription was given on 12/14/2022. The instructions for venlafaxine ER 150 mg capsule are to take 1 capsule (150 mg) orally once daily in the afternoon with food. This prescription was refilled on 12/14/2022. The venlafaxine ER 37.5 mg capsule was replaced with Vraylar 1.5 mg capsule once daily in the afternoon with venlafaxine ER 187.5 mg for bipolar depression.

The future plan of care discussed with the patient includes the possibility of switching to Caplyta or lithium carbonate if she is unable to tolerate Vraylar. The phone number provided for contact is (509) 339-2894, and the fax number is (509) 886-7484. The prescription for Vraylar is for 30 capsules with 0 refills.

In addition to the medication plan, there is a discussion about the insurance coverage and formulary. Caplyta is mentioned as a non-preferred drug that requires prior authorization, while Vraylar is a preferred drug that also requires prior authorization. Latuda is mentioned as another atypical antipsychotic medication that may require prior authorization or step therapy before Vraylar. The next follow-up visit with the NP is scheduled in 4 weeks, with the option for an earlier visit if necessary. The prescriptions for Vraylar and venlafaxine ER were sent to Rite Aid #05319 in Richland, WA, as per patient's request.

The progress note also mentions the discontinuation of Latuda due to unsatisfactory treatment response for bipolar depression. It was replaced with Vraylar 1.5 mg capsule once daily. Patient's risk for self-harm or harm to others is assessed as low, although her chronic risk is moderately elevated due to static and dynamic risk factors. She is aware of emergency procedures and can access emergency care if necessary.

The progress note also includes information about other medications prescribed to Patient, such as Sulfur 2% Cleanser and Tretinoin (Retinol A) Cream 0.025% for acne, and Ubrelvy 100 mg oral tablet for migraine headaches.    

The prior authorization request for Vraylar is mentioned, and more information is needed to review the request. The dosage strengths of Vraylar are listed as 20 mg, 40 mg, 60 mg, 80 mg, and 120 mg tablets, and it must be taken with at least 350 calories, usually with dinner or supper.
 `,
 "PBMCase1.pdf":`General Summary:
    • COB: N
• Requesting: Lidocaine 5% EX PTCH Place 1 patch onto the skin every 12 (twelve) hours, on for 12
hours and off for 12 hours in any 24 hour period 10/10 days
• Diagnosis: M25.551-Pain in right hip G89.29-Other chronic pain
• Member PA History: This is an initial request, brand new start.
• PA Type/Indicator: PDLP PA
• Reject Message: 75 PA Required
• Specialty Drug List: No
• Provider FCI: Provider did not document any med trials
• Claim History: Meloxicam 15 MG 11/28/2023
• Notes: MD notes sent dated 12/26/2023 Devon is here today with increased hip pain.
She has cerebral palsy. This pain has progressively gotten more painful over last 6 months. Recently,
especially her right hip, has been "popping out".Chronic right hip pain lidocaine (LIDODERM) 5 %;
Place 1 patch onto the skin every 12 (twelve) hours. On for 12 hours, off for 12 hours in any 24 hour
period. ibuprofen (MOTRIN) 600 mg tablet; Take 1 tablet (600 mg total) by mouth every 8 (eight) hours
as needed for Pain for up to 10 days.
- Ambulatory Referral to Sports Medicine
 `,
 "PBMCase2.pdf":`General Summary:• COB: N
• Requesting: Cibinqo 100MG OR TABS, #30/30ds, SIG: Pg 2 CN 12/11/23)
• Diagnosis: L20.89-Other atopic dermatitis
• Member PA History: This is an initial request, but the member is currently taking the med (Cibinqo 200mg Samples given to patient (pg 1 CN)).
• PA Type/Indicator: NF
• Reject Message: MR PRODUCT NOT ON FORMULARY
• Specialty Drug List: No
• Provider FCI: Provider did not document any med trials
• Claim History: no paid claims for Cibinqo or any other related meds
• Notes: 50yo male with atopic dermatitis, Pt is almost completely clear today after taking the 2 week sample of 200mg Cibinqo - I did provide him with more samples but decreased to 100mg daily (pg 2 CN)
`,
 "PBMCase3.pdf":`General Summary:
      • COB: N
      • Requesting: Retin-A 0.05% EX CREAM: 20/30ds:SIG: apply a pea size amount to affeceted area at
      bedtime, CN pg 1
      • Diagnosis: L70.0-Acne vulgaris
      • Member PA History: This is an initial request, brand new start.
      • PA Type/Indicator: Preferred AGE (Max 26 Years)
      • Reject Message: 60 DrugNotCvrd forPatientAge Maximum Patient Age of 26 76 Plan Limitations
      Exceeded
      • Specialty Drug List: No
      • Provider FCI: Per Chart notes, Pg 3, adapelene, BPO, Doxy
      • Claim History: No paid claims for Retin-A, mupirocin 1/25/24
      • Notes: Chart notes 1/25/24, pg 1, mupirocin and Retin-A. PG 3, on face
 `,
  "PBMCase4.pdf":`General Summary:
  • MCP Agents: N
• COB: N
• Requesting: Botox SOLR 200UNIT
qty 1/84 days Sig: not provided
• Diagnosis: G43.709-Chronic migraine w/o aura, not intractable, w/o stat migr • Member PA History: Medication was prevously denied on 10/13/2023
• PA Type/Indicator: NF
• Reject Message: MR Non-Formulary Drug
• Specialty Drug List: N
• Provider FCI: Per pa form page 4, member has tried and failed Amitriptyline, qulipta, and depakote. Per cn page 11, member tried and failed xoloft, gabapentin, and baclofen.
• Claim History: No claims for preferred drug trials
•Notes:
Chart notes and labs from 09/06/2023.
The Provider Specialty is neurologist.
Medication is FDA indicated.
Per criteria, Request for COT ;Pend to RPH for review.
  `
};

export const guidelineCompliance: AdjudicationSummaryItem[] = [
  {
    compliance_status: true,
    guideline_description:
      "Cariprazine may be covered when the client is 18 years of age or older.",
    observation:
      "The patient, is 22 years old, which is older than the required age of 18 as per the guideline.",
  },
  {
    compliance_status: true,
    guideline_description:
      "Clients 17 years of age or younger require a second opinion review with the agency-designated mental health specialist from the Second Opinion Network (SON).",
    observation: "As the patient is 22 years old, this guideline is not applicable.",
  },
  {
    compliance_status: false,
    guideline_description:
      "Client meets ONE of the following: a. History of either failure after 4 weeks, contraindication, or intolerance to THREE of the following oral atypical antipsychotics: i. Lurasidone ii. Olanzapine+Fluoxetine (Combination product or individual products taken concurrently) iii. Quetiapine b. Documentation that client has been taking cariprazine and is stabilized at the requested dose.",
    observation:
      "The patient's medical history does not provide any information about a history of failure, contraindication, or intolerance to Lurasidone, Olanzapine+Fluoxetine, or Quetiapine. Additionally, there is no documentation indicating that the patient has been taking cariprazine and is stabilized at the requested dose.",
  },
  {
    compliance_status: false,
    guideline_description: "Client has a CrCl >30mL/min.",
    observation:
      "The patient's medical history does not provide any information about the patient's CrCl levels.",
  },
  {
    compliance_status: false,
    guideline_description: "Client has no severe hepatic impairment (Child Pugh Score ≥10).",
    observation:
      "The patient's medical history does not provide any information about the patient's hepatic function or Child Pugh Score.",
  },
];

export const authorization: ProcedureRequest = {
  adjudication_summary: [
    `Guideline Description: Cariprazine may be covered when the client is 18 years of age or older.
Observation: The patient, is 22 years old, which is older than the required age of 18 as per the guideline.
Compliance Status: COMPLIANT`,

    `Guideline Description: Clients 17 years of age or younger require a second opinion review with the agency-designated mental health specialist from the Second Opinion Network (SON).
Observation: As the patient is 22 years old, this guideline is not applicable.
Compliance Status: COMPLIANT`,

    `Guideline Description: Client meets ONE of the following: a. History of either failure after 4 weeks, contraindication, or intolerance to THREE of the following oral atypical antipsychotics: i. Lurasidone ii. Olanzapine+Fluoxetine (Combination product or individual products taken concurrently) iii. Quetiapine b. Documentation that client has been taking cariprazine and is stabilized at the requested dose.
Observation: The patient's medical history does not provide any information about a history of failure, contraindication, or intolerance to Lurasidone, Olanzapine+Fluoxetine, or Quetiapine. Additionally, there is no documentation indicating that the patient has been taking cariprazine and is stabilized at the requested dose.
Compliance Status: NON COMPLIANT`,

    `Guideline Description: Client has a CrCl >30mL/min.
Observation: The patient's medical history does not provide any information about the patient's CrCl levels.
Compliance Status: NON COMPLIANT`,

    `Guideline Description: Client has no severe hepatic impairment (Child Pugh Score ≥10).
Observation: The patient's medical history does not provide any information about the patient's hepatic function or Child Pugh Score.
Compliance Status: NON COMPLIANT`,
  ],
  overview: `Recap: The patient's compliance status for procedure authorization is non-compliant based on explicit evidence. The patient is compliant with the age-related guidelines but is non-compliant with the guidelines related to the history of antipsychotic use, CrCl levels, and hepatic function.
  PATIENT CONDITIONS ARE COMPLIANT FOR PROCEDURE: NO
  EXPLANATION FOR NON COMPLIANCE: The patient's medical history does not provide information about a history of failure, contraindication, or intolerance to the specified antipsychotics, nor does it provide information about the patient's CrCl levels or hepatic function. For compliance authorization, additional patient evidence or medical history is needed regarding these specific guideline requirements.
  `,
};
