import React, { useCallback, useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { PdfViewer } from "../../../components";
import { openNotificationWithIcon } from "../../../utils";
import {  HighlightedSource } from "./HighlightedSource";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentChunkUUID, setCurrentPage as setGlobalCurrentPage } from "../../../store/document/slice";
import { RootState } from "../../../store";

type Props = {
  chatSource: ISources;
  setChatSource: (value: React.SetStateAction<ISources>) => void;
  fileUrl: string;
  guideline?:string;
  isCompliantDoc?: boolean;
};

type IOffset = {
  begin: number;
  end: number;
};

type IPages = {
  pageNo: number;
  offsets: IOffset[];
  chunkUUID: string;
};

type ISources = {
  category: string;
  pages: IPages[];
  documentUUID: string;
  guidelines?: boolean;
};

export const SourcePopup = ({
  chatSource,
  setChatSource,
  guideline,
  fileUrl,
  isCompliantDoc,
}: Props) => {
  const { currentPage,currentChunkUUID } = useSelector((state: RootState) => state.document);

  const dispatch = useDispatch();
  const downloadPDF = async (url: string, filename: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      openNotificationWithIcon("", "Downlaod Failed", "error");
    }
  };

  const handleFileDownload = async () => {
    await downloadPDF(fileUrl, `source_${new Date().getTime()}.pdf`);
  };
  const renderPdfViewer = useCallback(()=>{
    return (
      <PdfViewer
        currPage={currentPage}
        pdf={fileUrl}
        displayPagination
        sourcePopup={true}
      />
    );
  }, [currentPage, fileUrl])

  const handlePageChange = (pageNo: number,chunkID?:string) => {
    dispatch(setGlobalCurrentPage(pageNo));
    dispatch(setCurrentChunkUUID(chunkID || ""))
  }
  return (
    <Modal
      className="autonomizeModal modal"
      centered
      width={1100}
      title="Sources"
      open={!!Object.keys(chatSource).length}
      footer={null}
      onCancel={() => setChatSource({} as ISources)}
      closeIcon={<CloseOutlined />}
    >
      <div className="modal-title">
        <h1>{chatSource.documentUUID}</h1>
      </div>
      <div className="d-flex gp-20" style={{ height: "85vh", minHeight: "60vh"}}>
        <div className="overflow-auto" style={{ minWidth: "60%" }}>
          {renderPdfViewer()}
        </div>
        <div className="sources-viewer-container">
          <div className="m-b">
            <h4>Results in</h4>
            <ul className="source-list">
              {chatSource?.pages?.map((page, index) => (
                <li
                  className={`cursor-pointer ${page.pageNo === currentPage && page.chunkUUID===currentChunkUUID ? "active" : ""}`}
                  key={index}
                  onClick={() => handlePageChange(page.pageNo,page.chunkUUID)}
                >{`Page Number ${page.pageNo}`}</li>
              ))}
            </ul>
          </div>
          <HighlightedSource
            chatSource ={chatSource}
            setCurrPage={(pages) => handlePageChange(pages)}
            guideline={guideline}
            isCompliantDoc={isCompliantDoc}
          />
          <div className="mt-10">
            <Button className="fill" onClick={handleFileDownload} disabled={fileUrl === ""}>
              Download
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
